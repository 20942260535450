import lottie from './_partials/lottie'
import mediaAccordion from './_partials/functionDetail/mediaAccordion'
import animateFunctionSections from './_partials/functions/animateFunctionSections'

document.addEventListener('DOMContentLoaded', () => {
  lottie()
  mediaAccordion()
  animateFunctionSections()
})

